.footer {
  background-color: var(--dark-blue);
  color: #fff;
  text-align: center;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
