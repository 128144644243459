.carousel-item {
  height: 300px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 20px;
  left: 10%;
  color: aliceblue;
  justify-content: left;
  z-index: 3;
}

.overlay h {
  font-size: 40px; /* Large font size for the title */
  font-weight: bold;
}

.overlay p {
  font-size: 1.5rem; /* Large font size for the additional text */
  margin-bottom: 10px;
}

.overlay a {
  width: fit-content;
}

/* override default of 15% */
.carousel-control-prev {
  width: 10% !important;
}
.carousel-control-next {
  width: 10% !important;
}

/* Responsive Styles */
@media (max-width: 430px) {
  .overlay {
    p {
      display: none;
      visibility: hidden;
    }
  }
}
