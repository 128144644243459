.nav-container {
  z-index: 5;
  position: sticky;
  top: 0;
}

.nav-banner {
  padding: 1em;
  text-align: center;
  background-color: #00b3b3;
  font-weight: bold;
}

.nav-bar {
  height: 100px;
  display: flex;
  background-color: var(--dark-blue);
  color: aliceblue;
  font-size: large;
  padding: 0.5rem;
}

.nav-btn {
  color: aliceblue !important;
  text-decoration: none !important;
}

.nav-btn:hover {
  text-decoration: none;
  color: aliceblue;
}

.nav-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 4;
}

.nav-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
}

.company-name {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  font-size: 12px;
  width: 170px;
}

.company-name * {
  margin: 0 auto;
}

.company-name h5 {
  font-weight: 800;
}

.nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.nav-item {
  padding: 10px;
  text-align: center;
}

.nav-item:hover {
  filter: brightness(50%);
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  align-self: center;
  padding: 1rem;
}

.hamburger span {
  height: 3px;
  width: 25px;
  background: aliceblue;
  margin: 4px 0;
  border-radius: 2px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav-options {
    display: none;
    flex: 0;
  }

  .menu-options {
    display: flex;
    flex-direction: column;
    background-color: aliceblue;
    text-align: center;
  }

  .menu-item {
    padding: 0.5rem;
    font-weight: bold;
    color: var(--dark-blue) !important;
  }

  .menu-item:hover {
    filter: brightness(50%);
  }

  .hamburger {
    display: flex;
  }
}

/* Responsive Styles */
@media (max-width: 450px) {
  .company-name {
    display: none;
  }
}

/* Responsive Styles  */
@media (min-width: 768px) {
  .menu-options {
    display: none;
  }
}
