@import "https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css";

:root {
  --main-bg-color-green: #aaebe8;
  --blocked-bg-color-gray: #cccbcb;
  --booked-bg-color-red: #f9c9c1;
  --past-bg-color-gray: #c4c2c2;

  --light-blue: #5f9ea0;
  --dark-blue: #1e90ff;
  --blue-highlight: #58b1ff;
  --yellow: #ffffb5;
  --green: #6dc06d;
  --light-green: #bde8cd;
  --light-grey: #abaaaa;
}
